<template>
  <div v-if="!isLoadingPage">
    <div v-if="permission !== null">
      <div v-if="permission.read_perm == 1">
        <div class="fluid d-flex flex-column" id="attendanceReport">
          <h4
            style="
              margin: auto;
              font-weight: bold;
              text-align: center;
              font-size: 20px;
              position: relative;
              top: 15px;
            "
          >
            LOG KEHADIRAN
            <v-tooltip bottom color="grey">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  x-small
                  text
                  icon
                  color="grey darken-2"
                  @click="refreshPage"
                >
                  <v-icon small>mdi-refresh</v-icon>
                </v-btn>
              </template>
              <span>refresh page</span>
            </v-tooltip>
          </h4>
          <div>
            <div class="attendance-list-div">
              <v-card class="px-5 py-3">
                <v-row>
                  <v-col cols="12">
                    <div>
                      <v-toolbar
                        class="attendance-list-toolbar-1"
                        flat
                        style="height: 60px; border-radius: 10px 10px 0 0"
                      >
                        <div class="d-flex" style="margin-right: auto">
                          <div
                            style="
                              padding: 10px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            <v-btn
                              elevation="2"
                              dense
                              rounded
                              small
                              color="primary"
                              class="white--text"
                              style="position: relative"
                              @click="addLogAttendance"
                              >Tambah Data</v-btn
                            >
                          </div>
                        </div>
                      </v-toolbar>
                      <v-toolbar
                        class="attendance-list-toolbar-1"
                        flat
                        style="height: 60px; border-radius: 10px 10px 0 0"
                      >
                        <div style="width: 180px">
                          <v-text-field
                            class="attendance-start-date ml-1 mr-2"
                            dense
                            label="Start Date"
                            type="date"
                            name="startdate"
                            style="height: 30px"
                            step="1"
                            outlined
                            v-model="start_date"
                          />
                        </div>

                        <div style="width: 180px">
                          <v-text-field
                            class="attendance-start-date ml-1 mr-2"
                            dense
                            label="End Date"
                            type="date"
                            name="enddate"
                            style="height: 30px"
                            step="1"
                            outlined
                            v-model="end_date"
                          />
                        </div>

                        <div style="width: 170px; margin-right: 10px">
                          <v-select
                            :value="2"
                            style="
                              height: 30px;
                              position: relative;
                              margin-left: 10px;
                              font-size: 16px;
                            "
                            :items="dropDownCompany"
                            item-text="name"
                            item-value="id"
                            return-id
                            dense
                            label="Company"
                            outlined
                            v-model="paramAPI.company_id"
                          ></v-select>
                        </div>
                        <div class="d-flex" style="margin-left: auto">
                          <v-text-field
                            v-model="paramAPI.keyword"
                            label="search here"
                            type="search"
                            append-icon="mdi-magnify"
                            outlined
                            dense
                            @keyup.enter="searchEnter"
                            style="position: relative; top: 15px"
                          ></v-text-field>
                          <div
                            style="
                              padding: 10px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                          >
                            <v-btn
                              elevation="2"
                              dense
                              rounded
                              small
                              color="primary"
                              class="white--text"
                              style="position: relative; left: 15px"
                              @click="searchEnter"
                              >search</v-btn
                            >
                          </div>
                        </div>
                      </v-toolbar>
                    </div>
                    <v-toolbar
                      class="attendance-list-toolbar-2"
                      flat
                      style="
                        height: 60px;
                        border-radius: 10px 10px 0 0;
                        border-bottom: 1px solid #e0e0e0;
                      "
                    >
                      <v-text-field
                        v-model="paramAPI.keyword"
                        label="Search here"
                        type="search"
                        outlined
                        dense
                        @keyup.enter="searchEnter"
                        style="position: relative; top: 15px"
                      ></v-text-field>
                    </v-toolbar>
                  </v-col>

                  <v-col cols="12">
                    <v-data-table
                      @click:row="rowClick"
                      fixed-header
                      height="57vh"
                      :headers="headers"
                      :items="result"
                      :items-per-page="10"
                      :loading="loading"
                      item-key="id"
                    >
                      <template v-slot:[`item.body_temperature`]="{ item }">
                        <v-chip
                          :color="getTemperatureStatus(item.body_temperature)"
                          dark
                        >
                          {{ item.body_temperature }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.company_name`]="{ item }">
                        <td>
                          {{
                            item.company_name.slice(
                              item.company_name.lastIndexOf('(') + 1,
                              item.company_name.lastIndexOf(')')
                            )
                          }}
                        </td>
                      </template>
                      <template v-slot:[`item.use_mask`]="{ item }">
                        <v-chip :color="getUseMaskStatus(item.use_mask)" green>
                          {{ getUseMaskName(item.use_mask) }}
                        </v-chip>
                      </template>
                      <template v-slot:[`item.shift_id`]="{ item }">
                        <v-tooltip right>
                          <template v-slot:activator="{ on, attrs }">
                            <div
                              v-bind="attrs"
                              v-on="on"
                              style="
                                padding: 0;
                                display: flex;
                                flex-direction: row;
                                width: 70px;
                              "
                            >
                              {{ item.shift_name }}
                            </div>
                          </template>
                          {{ item.shift_name }}
                          <span style="font-size: 12px; margin-left: 1px">
                            [id:{{ item.shift_id }}]
                          </span>
                        </v-tooltip>
                      </template>

                      <template v-slot:[`item.action`]="{ item }">
                        <v-tooltip v-if="permission.delete_perm == 1" right>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              x-small
                              icon
                              elevation="2"
                              outlined
                              class="error--text font-weight-bold"
                              @click.stop="deleteItem(item)"
                            >
                              <v-icon small> mdi-delete </v-icon>
                            </v-btn>
                          </template>
                          <span>Delete</span>
                        </v-tooltip>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </v-card>
            </div>
          </div>
        </div>
      </div>
    </div>

    <v-card
      v-else
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      DATA NOT FOUND
    </v-card>

    <v-dialog
      v-if="selectedItem != null"
      v-model="editDialog"
      width="800"
      persistent
    >
      <v-card style="position: relative">
        <v-card-title class="text-h5 grey lighten-2">
          Detail Waktu Kehadiran
        </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <div
            class="d-flex"
            style="position: absolute; right: 10px; top: 15px"
          >
            <v-btn
              v-if="!isEdit"
              @click="editLog"
              type="button"
              text
              elevation="0"
              color="primary"
              class="white--text font-weight-bold"
              style="font-size: 12px"
              :loading="loading"
            >
              <v-icon small>mdi-pencil</v-icon>
              Edit Log
            </v-btn>
            <v-btn
              v-else
              color="error"
              text
              outlined
              :loading="loading"
              @click="editLog"
            >
              Batal
              <v-icon small>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Nama
              </p>
              <v-text-field
                v-model="selectedItem.employee_name"
                dense
                outlined
                readonly
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                No. Induk Karyawan
              </p>
              <v-text-field
                v-model="selectedItem.employee_code"
                dense
                outlined
                readonly
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Perusahaan
              </p>
              <v-text-field
                v-model="selectedItem.company_name"
                dense
                outlined
                readonly
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Periode presensi
              </p>
              <v-text-field
                v-model="selectedItem.period_date"
                :clearable="isEdit"
                dense
                outlined
                type="date"
                name="date"
                step="1"
                style="height: 45px"
                :readonly="!isEdit"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Waktu presensi
              </p>
              <v-text-field
                :value="selectedItem.attendance_time"
                dense
                outlined
                readonly
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                shift
              </p>
              <v-text-field
                v-if="!isEdit"
                v-model="selectedItem.shift_name"
                dense
                outlined
                readonly
                style="height: 45px"
              />
              <v-autocomplete
                v-else
                v-model="selectedItem.shift_id"
                :items="dropdownShift"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 45px"
                clearable
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            text
            outlined
            :loading="loading"
            @click="closeEditDialog"
          >
            Tutup
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            v-if="isEdit"
            color="primary"
            type="button"
            text
            outlined
            :loading="loading"
            @click="saveUpdateLog"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="selectedItem != null"
      v-model="addDialog"
      width="800"
      persistent
    >
      <v-card style="position: relative">
        <v-card-title class="text-h5 grey lighten-2">
          Tambah Waktu Kehadiran
        </v-card-title>

        <v-card-text class="d-flex justify-center align-center">
          <v-row class="mt-3">
            <v-col cols="12" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Perusahaan
              </p>

              <v-select
                v-model="selectedItem.company"
                style="height: 30px; position: relative; font-size: 16px"
                :items="dropDownCompany"
                item-text="name"
                item-value="id"
                return-object
                dense
                label="Company"
                outlined
                @change="companyWatcher"
              ></v-select>
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Karyawan
              </p>
              <div style="height: 40px">
                <v-autocomplete
                  v-model="selectedItem.employee"
                  :items="dropdownEmployee"
                  :search-input.sync="employeeSearch"
                  dense
                  clearable
                  outlined
                  item-text="name"
                  item-value="id"
                  return-object
                  label="ketik nama"
                  :disabled="
                    selectedItem == null || selectedItem.company == null
                  "
                >
                  <template v-slot:item="data">
                    <template>
                      <v-list-item-avatar>
                        <img :src="`${data.item.photo}`" />
                      </v-list-item-avatar>
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ data.item.name }}
                        </v-list-item-title>
                        <v-list-item-subtitle>
                          {{ data.item.company_name }}-{{ data.item.nik }}
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </div>
            </v-col>
            <v-col
              v-if="selectedItem.employee != null"
              cols="12"
              md="6"
              class="d-flex flex-column"
            >
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                No. Induk Karyawan
              </p>
              <v-text-field
                :value="selectedItem.employee.nik"
                dense
                outlined
                readonly
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Periode presensi
              </p>
              <v-text-field
                v-model="selectedItem.period_date"
                dense
                outlined
                type="date"
                name="date"
                step="1"
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                Waktu presensi
              </p>
              <v-text-field
                v-model="selectedItem.attendance_time"
                dense
                outlined
                type="datetime-local"
                style="height: 45px"
              />
            </v-col>
            <v-col cols="12" md="6" class="d-flex flex-column">
              <p
                class="text-left"
                style="
                  margin: 0;
                  font-size: 12px;
                  font-weight: bold;
                  color: rgba(0, 0, 0, 0.5);
                "
              >
                shift
              </p>
              <v-autocomplete
                v-model="selectedItem.shift_id"
                :items="dropdownShift"
                item-text="name"
                item-value="id"
                return-id
                outlined
                dense
                style="margin: 0; height: 45px"
                clearable
                :disabled="selectedItem == null || selectedItem.company == null"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="error"
            text
            outlined
            :loading="loading"
            @click="closeAddDialog"
          >
            Tutup
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="button"
            text
            outlined
            :loading="loading"
            @click="saveAddLog"
          >
            Simpan
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
  <div v-else>
    <v-card
      class="mx-auto"
      width="100%"
      style="
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        position: fixed;
        top: 0;
        height: 100%;
        left: 0;
        right: 0;
        background: rgba(255, 255, 255);
      "
    >
      <v-progress-circular :size="170" :width="10" color="blue" indeterminate
        >Loading...</v-progress-circular
      >
    </v-card>
  </div>
</template>
<script>
import axios from 'axios'
import { mapActions, mapGetters } from 'vuex'
import buildType from '../../../services/buildType'
const env = process.env.VUE_APP_BUILD_TYPE
export default {
  name: 'attendancelog',
  data: () => ({
    config: require('../../../services/config.json')[env],
    attendance: buildType.apiURL('attendance'),
    hrsApi: buildType.apiURL('hrs'),
    eSanqua: buildType.apiURL('esanqua'),
    oAuthApi: buildType.apiURL('oauth'),
    permission: null,
    appLevel: null,
    userLevel: null,
    isLoadingPage: true,
    isDisableCompanyDropdown: false,
    paramAPI: {
      company_id: null,
      keyword: ''
    },
    company: '',
    temperature: '',
    mask: '',
    headers: [
      {
        text: 'Log Time',
        value: 'attendance_time',
        align: 'left',
        sortable: false
      },
      {
        text: 'NIK',
        value: 'employee_code',
        align: 'left',
        sortable: false
      },
      {
        text: 'Name',
        value: 'employee_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Company',
        value: 'company_name',
        align: 'left',
        sortable: false
      },
      {
        text: 'Period Date',
        value: 'period_date',
        align: 'left',
        sortable: false
      },
      {
        text: 'Shift ID',
        value: 'shift_id',
        align: 'left',
        sortable: false
      },
      // {
      //   text: 'Mask',
      //   value: 'use_mask',
      //   align: 'left',
      //   sortable: false
      // },
      // {
      //   text: 'Temperature',
      //   value: 'body_temperature',
      //   align: 'left',
      //   sortable: false
      // },
      {
        text: 'Actions',
        value: 'action',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    loading: false,
    start_date: '',
    end_date: '',
    dropDownCompany: [],
    dropdownShift: [],
    dropdownDevice: [],
    dropdownEmployee: [],
    selectedItem: null,
    editDialog: false,
    isEdit: false,
    addDialog: false,
    employeeSearch: null,
    employeeSelect: null
  }),
  watch: {
    temperature() {
      console.log(this.temperature)
    },
    employeeSearch(val) {
      if (val !== null) {
        if (val !== '') {
          val && val !== this.employeeSelect && this.querySelections(val)
        } else {
          this.employeeSelect = null
          this.dropdownEmployee = []
        }
      } else {
        this.employeeSelect = null
        this.dropdownEmployee = []
      }
    }
  },
  components: {},
  mounted() {
    const d = new Date()
    // d.setDate(d.getDate() - 1)
    const n = d.toLocaleDateString('id').split('/')
    const day = n[0].length === 1 ? `0${n[0]}` : n[0]
    const month = n[1].length === 1 ? `0${n[1]}` : n[1]
    const year = n[2]
    const join = `${year}-${month}-${day}`
    // .reverse()
    // .join('-')
    this.start_date = join
    this.end_date = join

    this.appLevel = this.config.application.hr.userLevel
    this.appLevel.administrator =
      this.config.application.all.userLevel.administrator
    this.getDataMenu()
  },
  computed: {
    ...mapGetters(['getUserProfile'])
  },
  methods: {
    ...mapActions(['menu_access', 'getMenuId']),

    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    companyWatcher(v) {
      console.log(v)
      if (v != null) {
        this.selectedItem.company_id = v.id
        this.getDropdownShift()
        this.getDropdownDevice()
      } else {
        this.dropdownShift = []
        this.dropdownDevice = []
        delete this.selected.company_id
      }
    },
    async querySelections(v) {
      this.loading = true

      let url = `${this.hrsApi}employee/dropdown?keyword=${v}`
      if (this.selectedItem.company !== null) {
        url = url + `&company_id=${this.selectedItem.company.id}`
      }
      await axios
        .get(url)
        .then((res) => {
          this.loading = false
          if (res.data.status_code === '00') {
            const resArr = []
            for (let i = 0; i < res.data.data.length; i++) {
              resArr.push({
                id: res.data.data[i].id,
                nik: res.data.data[i].nik,
                name: res.data.data[i].name,
                company_id: res.data.data[i].company_id,
                company_name: this.getCompanyAlias(res.data.data[i].company_id),
                photo: res.data.data[i].photo
              })
            }
            return (this.dropdownEmployee = resArr)
          }
          return (this.dropdownEmployee = [])
        })
        .catch((err) => {
          this.loading = false
          this.dropdownEmployee = []
          return console.log(err)
        })
    },
    async getDataMenu() {
      this.isLoadingPage = true
      await this.getMenuId({
        keyword: '',
        path: this.$route.path
      })
        .then((result) => {
          if (result.data.status_code === '00') {
            const menuId = result.data.data[0].id
            this.userLevel = this.getUserProfile.level.find(
              ({ application }) =>
                application.id === this.config.application.hr.applicationId ||
                application.id === this.config.application.all.applicationId
            )
            console.log('userLevel>>>>>', this.userLevel)
            if (this.userLevel !== null) {
              if (
                this.getUserProfile.level.find(
                  ({ id }) => id == this.appLevel.administrator
                ) !== undefined
              ) {
                this.resetPermission({
                  data: {
                    read_perm: 1,
                    create_perm: 1,
                    update_perm: 1,
                    delete_perm: 1
                  }
                })
              } else {
                this.checkMenuAccess([menuId, this.userLevel.id])
              }
            } else {
              this.resetPermission(null)
            }
          } else {
            this.isLoadingPage = false
          }
        })
        .catch((err) => {
          console.log(err)
          this.isLoadingPage = false
        })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          console.log('menuAccess>>>>', result)
          this.resetPermission(result)
        })
        .catch((err) => {
          console.log(err)
          this.resetPermission(null)
        })
    },
    resetPermission(val) {
      this.permission = null

      if (val !== null) {
        if (val.data !== null) {
          if (val.data.read_perm === 1) {
            this.permission = val.data
            this.initDataForLayout()
          }
        }
      }
      this.isLoadingPage = false
    },
    initDataForLayout() {
      if (this.userLevel.is_support) {
        this.paramAPI.company_id = 0
      } else {
        this.paramAPI.company_id = Number(
          this.getUserProfile.employee.company.plant_id
        )
      }
      setTimeout(async () => {
        this.dropdownCompany()
        this.initDataTable()
      }, 200)
    },
    dropdownCompany() {
      if (this.userLevel !== null) {
        // if (this.userLevel.is_admin !== null) {
        if (this.userLevel.user_user_level !== null) {
          if (
            this.userLevel.user_user_level.allowed_company === null ||
            this.userLevel.user_user_level.allowed_company.length === 0
          ) {
            this.dropdownCompanyApi()
          } else {
            this.dropDownCompany =
              this.userLevel.user_user_level.allowed_company
            this.isDisableCompanyDropdown = false
          }
        } else {
          this.dropdownCompanyApi()
        }
        // }
      }
    },
    async dropdownCompanyApi() {
      if (Number(this.getUserProfile.employee.company.plant_id) !== 6) {
        this.isDisableCompanyDropdown = this.userLevel.is_support
      } else {
        this.isDisableCompanyDropdown = false
      }
      axios
        .get(`${this.eSanqua}master/universal/plant/dropdown`)
        .then((res) => {
          this.dropDownCompany = res.data.data
        })
        .catch((err) => {
          console.log(err)
        })
    },
    searchEnter() {
      this.initDataTable()
    },
    refreshPage() {
      this.loading = true
      // const d = new Date()
      // d.setDate(d.getDate() - 1)
      // const n = d.toLocaleDateString('id').split('/')
      // const day = n[0].length === 1 ? `0${n[0]}` : n[0]
      // const month = n[1].length === 1 ? `0${n[1]}` : n[1]
      // const year = n[2]
      // const join = `${year}-${month}-${day}`
      // // .reverse()
      // // .join('-')
      // this.start_date = join
      // this.end_date = join
      // this.paramAPI.keyword = ''
      // this.paramAPI.company_id = null
      this.result = []
      setTimeout(() => {
        this.initDataTable()
      }, 1000)
    },
    initDataTable() {
      axios
        .get(
          `${this.attendance}attendance_device/log/list?start_date=${this.start_date}&end_date=${this.end_date}&keyword=${this.paramAPI.keyword}&company_id=${this.paramAPI.company_id}&offset=0&limit=all`
        )
        .then((res) => {
          console.log(res)
          this.result = res.data.data
          this.loading = false
        })
        .catch((err) => {
          console.log(err)
          this.loading = false
        })
    },
    // exportReport() {
    //   axios
    //     .get(
    //       `${this.attendance}report/attendance/generateToExcel?start_date=${this.start_date}&end_date=${this.end_date}&company_id=${this.company}`
    //     )
    //     .then(res => {
    //       console.log(res.data)
    //       this.recapitulationAttendances = []
    //       if (res.data.status_code === '00') {
    //         this.recapitulationAttendances = res.data.formed_data

    //         setTimeout(() => {
    //           document.getElementById('exportRecap').click()
    //         }, 500)
    //       }
    //     })
    //     .catch(err => {
    //       console.log(err)
    //     })
    // },
    rowClick(pItem) {
      console.log('pItem>>>', pItem)
      this.selectedItem = pItem
      setTimeout(() => {
        this.selectedItem.act = 'update'
        this.editDialog = true
      }, 300)
    },
    addLogAttendance() {
      this.selectedItem = {
        attendance_time: null,
        period_date: null,
        shift_id: null,
        company: null,
        employee: null
      }
      setTimeout(() => {
        this.addDialog = true
      }, 300)
    },
    closeAddDialog() {
      this.addDialog = false
      this.dropdownShift = []
      this.dropdownDevice = []
      setTimeout(() => {
        this.selectedItem = null
      }, 300)
    },
    closeEditDialog() {
      this.editDialog = false
      this.addDialog = false
      if (this.isEdit) {
        this.isEdit = false
        this.dropdownShift = []
        this.dropdownDevice = []
      }
      setTimeout(() => {
        this.selectedItem = null
      }, 300)
    },
    editLog() {
      this.isEdit = !this.isEdit
      if (this.isEdit) {
        this.getDropdownShift()
      } else {
        this.dropdownShift = []
      }
    },
    saveAddLog() {
      const form = {
        act: 'add',
        shift_id: this.selectedItem.shift_id,
        period_date: this.selectedItem.period_date,
        attendance_time: this.selectedItem.attendance_time,
        company_name: this.selectedItem.company.name,
        company_id: this.selectedItem.company.id,
        employee_code: this.selectedItem.employee.nik,
        employee_name: this.selectedItem.employee.name,
        employee_id: this.selectedItem.employee.id,
        device_type: 4,
        device_code:
          this.dropdownDevice.length > 0 ? this.dropdownDevice[0].code : null,
        device_id:
          this.dropdownDevice.length > 0 ? this.dropdownDevice[0].id : null,
        is_valid_attendance: true
      }
      console.log(form)
      this.submitLog(form)
    },
    saveUpdateLog() {
      const form = {
        act: this.selectedItem.act,
        shift_id: this.selectedItem.shift_id,
        period_date: this.selectedItem.period_date
      }
      // if (form.act === 'add') {
      // }

      if (form.act === 'update') {
        Object.assign(form, { id: this.selectedItem.id })
      }
      console.log(form)
      this.submitLog(form)
    },
    async submitLog(form) {
      this.loading = true
      await axios
        .post(`${this.attendance}log/save`, form)
        .then(async (res) => {
          console.log(res.data)
          this.loading = false
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.initDataTable()
            this.closeEditDialog()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'warning',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('warning', res.data.status_msg, false)
            }
          }
        })
        .catch((err) => {
          console.log(err)
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },

    async getDropdownShift() {
      const url = `${this.hrsApi}master/universal/shift/dropdown?filter=[{"company_id":${this.selectedItem.company_id}}]`
      await axios
        .get(url)
        .then((res) => {
          console.log('shift>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdownShift = res.data.data)
          }
          return (this.dropdownShift = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdownShift = [])
        })
    },
    async getDropdownDevice() {
      console.log('attendancedevice')
      const url = `${this.hrsApi}master/universal/attendancedevice/dropdown?filter=[{"company_id":${this.selectedItem.company_id}}]`
      await axios
        .get(url)
        .then((res) => {
          console.log('attendancedevice>>>', res)
          if (res.data.status_code === '00') {
            return (this.dropdownDevice = res.data.data)
          }
          return (this.dropdownDevice = [])
        })
        .catch((err) => {
          console.log(err)
          return (this.dropdownDevice = [])
        })
    },
    deleteItem(pItem) {
      console.log('delete>>>>', pItem)
      this.showMsgDialog(
        'question',
        'Are you sure, This data will be deleted permanently',
        true
      ).then(async (res) => {
        if (res.isConfirmed) {
          await axios
            .delete(
              `${this.attendance}attendance_device/log/delete/${pItem.id}`
            )
            .then(async (res) => {
              if (res.data.status_code !== '-99') {
                this.showMsgDialog('success', res.data.status_msg, false)
                this.refreshPage()
              } else {
                this.showMsgDialog('warning', res.data.status_msg, false)
              }
            })
            .catch((err) => {
              console.log(err)
              this.showMsgDialog(
                'error',
                err
                  ? 'Something went wrong, Please contact an admin!'
                  : 'Something went wrong, Please contact an admin!',
                false
              )
            })
        }
      })
    },
    convertDate(raw) {
      if (raw !== null) {
        const year = raw.slice(0, raw.indexOf('-'))
        const month = raw.slice(raw.indexOf('-') + 1, raw.lastIndexOf('-'))
        const day = raw.slice(raw.lastIndexOf('-') + 1)
        // return (day + '-' + month + '-' + year).replace(/-/g, '/')
        return day + '-' + month + '-' + year
      }
    },
    getTemperatureStatus(pTemperature) {
      // if (pType === 'in') {
      if (pTemperature >= 37.3) {
        return 'red'
      } else {
        return ''
      }
      // }
    },
    getUseMaskStatus(pMask) {
      // if (pType === 'in') {
      if (pMask === 1) {
        return ''
      } else {
        return 'red'
      }
      // }
    },
    getUseMaskName(pMask) {
      // if (pType === 'in') {
      if (pMask === 1) {
        return 'YES'
      } else {
        return 'NO'
      }
      // }
    },
    getCompanyAlias(id) {
      switch (id) {
        case 1:
          return 'TMP'
        case 2:
          return 'IMP'
        case 3:
          return 'GIT'
        case 4:
          return 'KAJ'
        case 5:
          return 'WIM'
        case 6:
          return 'SMI'
        case 7:
          return 'THP'
        case 8:
          return 'GAI'
        case 9:
          return 'GPP'
        case 10:
          return 'TMP2'
        case 11:
          return 'INO'
        case 12:
          return 'BPS'
        case 13:
          return 'BPC'
        case 14:
          return 'WIMS'
        case 15:
          return 'KAJ2'
      }
    }
  }
}
</script>

<style lang="scss">
#attendanceReport {
  margin-bottom: 10px;
  .attendance-list-div {
    padding: 40px 15px;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;

    .attendance-list-toolbar-1 {
      .attendance-list-toolbar-div {
        width: 30%;
        display: flex;
        flex-direction: row;
        margin-left: auto;
      }
    }
    .attendance-list-toolbar-2 {
      display: none;
    }
  }
}

.arch-add-new-btn {
  padding: 1px 4px;
  box-shadow: 1px 1px 1px rgb(134, 132, 132);
  border: 1px solid #cacad4;
  cursor: pointer;
  margin-top: 5px;
  text-align: left;
  font-size: 12px;
  &:hover {
    box-shadow: 1px 2px 7px rgb(134, 132, 132);
    color: blue;
    text-decoration: underline;
  }
}

.arch-expDate {
  cursor: pointer;
  height: 40px;
  .arch-exp-date {
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;

    .arch-exp-date-notif {
      overflow: hidden;
      width: 120px;
      position: absolute;
      left: 0;
      .exp-date-notif-div {
        display: flex;

        .exp-date-notif-chip1 {
          width: 95px;
          font-size: 10px;
        }
        .exp-date-notif-chip2 {
          font-size: 10px;
          .date-notif-chip2-p {
            width: 70px;
            margin: 0;
            margin: auto;
            text-align: center;
            // padding: 0 7px;
          }
        }
      }
    }
  }

  &:hover .arch-exp-date-notif {
    width: 0;
    transition: 0.5s;
  }
}
@media (max-width: 768px) {
  #attendanceReport {
    .attendance-list-div {
      .attendance-list-toolbar-1 {
        .attendance-list-toolbar-div {
          display: none;
        }
      }
      .attendance-list-toolbar-2 {
        display: block;
      }
    }
  }
}
</style>
